import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';

import Layout from '../../components/Layout';
import BlogRollTemplate from '../../components/BlogRoll';

BlogRollTemplate.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

const digitalTitle = "I AM DIGITAL";
const digitalDescription = "I am Studio offers a wide range of digital services. We offer customised Web Design, E-commerce, Social Media Marketing, Video integration, Three.js and Lottie Animations, SEO optimisation.";

export default function DigitalIndexPage({ location }) {
  if (location?.state?.filter) {
    var detailFilter = location.state.filter;
  }
  return (
    <Layout>
      <Helmet titleTemplate={digitalTitle}>
        <title>{digitalTitle}</title>
        <meta name="info" content={digitalDescription} />
        <meta name="description" content={digitalDescription} />
        <meta property="og:title" content={digitalTitle} />
        <meta property="og:description" content={digitalDescription} />
      </Helmet>
      <StaticQuery
        query={graphql`
          query DigitalBlogRollQuery {
            allMarkdownRemark(
              sort: { order: DESC, fields: [frontmatter___date] }
              filter: {
                frontmatter: {
                  templateKey: { eq: "portfolio-post" }
                  category: { eq: "digital" }
                }
              }
            ) {
              edges {
                node {
                  excerpt(pruneLength: 400)
                  id
                  fields {
                    slug
                  }
                  frontmatter {
                    title
                    tags
                    templateKey
                    date(formatString: "MMMM YYYY")
                    admin {
                      backgroundcolor
                      featuredimage
                      featuredimage2
                      featuredpost
                      hiddenpost
                      nonparallaxpost
                    }
                  }
                }
              }
            }
            markdownRemark(fields: { slug: { eq: "/utils/digitalOrder/" } }) {
              frontmatter {
                digitalOrder {
                  digitalItem
                }
                category
              }
            }
          }
        `}
        render={(data) => (
          <BlogRollTemplate
            data={data}
            category="DIGITAL"
            detailFilter={detailFilter}
          />
        )}
      />
    </Layout>
  );
}
